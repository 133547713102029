import { useAsyncFn, useEffectOnce, useSetState } from 'react-use'

import { useFetchMarketsApi } from 'api/masterData/queries/useFetchMarketsApi'
import { TableDefaults } from 'constants/table'
import { useMasterDataAttachments } from 'hooks/attachments/useMasterDataAttachments'
import { Geography } from 'types/masterData/geographies'
import { Market, MarketType } from 'types/masterData/markets'
import { excludeFalsy } from 'utils/common'

interface State {
  page: number
  marketsData: Market[]
  hasNextPage: boolean
}

interface Params {
  geographyId: Geography['id']
  filterType: MarketType
}

const PILLS_PAGE_SIZE = 40
export const useGeographyMarketsLoader = ({ geographyId, filterType }: Params) => {
  const handleFetchMarketsList = useFetchMarketsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const { handleLoadAttachments, getAttachmentUrlByKey } = useMasterDataAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ marketsData, page, hasNextPage }, setState] = useSetState<State>({
    page: 1,
    marketsData: [],
    hasNextPage: false,
  })

  const [{ loading: isLoading }, handleFetch] = useAsyncFn(async () => {
    const {
      data: {
        data,
        paginator: { totalPages },
      },
    } = await handleFetchMarketsList({
      itemsPerPage: PILLS_PAGE_SIZE,
      regionId: [geographyId],
      filterType,
      page,
    })

    const thumbnailKeys = data.map(({ logoThumbnail }) => logoThumbnail?.key).filter(excludeFalsy)

    await handleLoadAttachments(thumbnailKeys)

    setState(({ marketsData: prevData }) => ({
      hasNextPage: totalPages > page,
      page: page + 1,
      marketsData: [...prevData, ...data],
    }))
  }, [geographyId, filterType, setState, handleLoadAttachments, page, handleFetchMarketsList])

  useEffectOnce(() => {
    handleFetch()
  })

  return { getAttachmentUrlByKey, marketsData, isLoading, hasNextPage, fetchNextPage: () => handleFetch() }
}

import { useAsyncFn } from 'react-use'

import { useFetchClientsApi } from 'api/masterData/queries/useFetchClientsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Client } from 'types/masterData/clients'

interface Params {
  search?: string
  agencyId?: string[]
}

export const handleReloadClientsListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CLIENTS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CLIENT, { entryId }] }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_CLIENTS)
}

export const useClientsLoader = ({ search, agencyId }: Params) => {
  const handleFetchClientsList = useFetchClientsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ loading: isLoading, value }, loader] = useAsyncFn<TableInfiniteLoader<Client>>(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchClientsList({
        itemsPerPage: size,
        sort: sort === 'asc' ? colId : `-${colId}`,
        search,
        agencyId,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, agencyId, handleFetchClientsList],
  )

  return { isLoading, loader, hasResults: !!value?.totalRowsCount }
}

import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onSubmit: () => void
}

const UnsavedConfirmationModal = ({ isOpen, onSubmit, onClose, onCloseComplete, id }: Props) => {
  const { t } = useTranslation()
  // to prevent double clicks
  const [confirmed, setConfirmed] = useState(false)

  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete} data-testid={id}>
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          {t('common.unsaved_changes.title')}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('common.unsaved_changes.body')}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('common.cancel')}
        </WppButton>
        <WppButton
          onClick={() => {
            onSubmit()
            setConfirmed(true)
            onClose()
          }}
          size="s"
          variant="primary"
          loading={confirmed}
          data-testid="apply"
        >
          {t('common.unsaved_changes.confirm')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showUnsavedConfirmationModal } = createNiceModal(
  UnsavedConfirmationModal,
  'unsaved-confirmation-modal',
)

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UZwOx{min-height:100%;padding-bottom:25px}.MBkij{flex:1 1 auto;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.HMSBT{display:flex;flex-grow:1;max-width:100%}.HMSBT::part(card){flex-grow:1;min-height:100%}.g9Qzb{max-width:calc(72% - 24px)}.DJ3fQ{flex-grow:1}.Wr9eu{width:28%;min-width:28%}`, "",{"version":3,"sources":["webpack://./src/pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,mBAAA,CAGF,OACE,aAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAGF,OACE,YAAA,CACA,WAAA,CACA,cAAA,CAEA,mBACE,WAAA,CACA,eAAA,CAIJ,OACE,0BAAA,CAGF,OACE,WAAA,CAGF,OACE,SAAA,CACA,aAAA","sourcesContent":[".container {\n  min-height: 100%;\n  padding-bottom: 25px;\n}\n\n.containerTitle {\n  flex: 1 1 auto;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.cardWrapper {\n  display: flex;\n  flex-grow: 1;\n  max-width: 100%;\n\n  &::part(card) {\n    flex-grow: 1;\n    min-height: 100%;\n  }\n}\n\n.cardWrapperWithCards {\n  max-width: calc(100% - 28% - 24px);\n}\n\n.blockWrapper {\n  flex-grow: 1;\n}\n\n.moreCardsBlock {\n  width: 28%;\n  min-width: 28%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UZwOx`,
	"containerTitle": `MBkij`,
	"cardWrapper": `HMSBT`,
	"cardWrapperWithCards": `g9Qzb`,
	"blockWrapper": `DJ3fQ`,
	"moreCardsBlock": `Wr9eu`
};
export default ___CSS_LOADER_EXPORT___;

import { GetSelectOptionLabelHandler } from '@platform-ui-kit/components-library'
import { WppSelect, WppListItem, WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, Ref, ReactNode, ComponentProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/select/Select.module.scss'
import { getKey, useSelectDropdownConfig } from 'components/common/select/utils'
import { useCommonLabelProps } from 'components/common/utils'

type WppSelectProps = ComponentProps<typeof WppSelect>

export interface SelectProps<T extends Record<string, any>> extends WppSelectProps {
  options: T[]
  isOptionDisabled?: (option: T) => boolean
  getOptionValue?: (option: T) => any
  getOptionLabel?: GetSelectOptionLabelHandler
  getOptionKey?: (option: T) => string
  renderOptionContent?: (option: T) => ReactNode
  customOption?: JSX.Element
  infinite?: boolean
  search?: string
  'data-testid'?: string
}

export const Select = forwardRef(function Select<T extends Record<string, any>>(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    getOptionKey = option => getKey(option),
    renderOptionContent,
    isOptionDisabled = () => false,
    labelConfig,
    labelTooltipConfig,
    locales,
    dropdownConfig,
    className,
    customOption,
    withSearch,
    withFolder,
    search,
    loading,
    infinite = false,
    'data-testid': dataTestId,
    ...rest
  }: SelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const { t } = useTranslation()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const dropdownConfigInner = useSelectDropdownConfig(dropdownConfig)

  const defaultLocales = useMemo<NonNullable<WppSelectProps['locales']>>(
    () => ({
      emptyText: t('common.select.empty_text'),
      clearAllText: t('common.select.clear_all_text'),
      selectAllText: t('common.select.select_all_text'),
      searchInputPlaceholder: t('common.select.search_input_placeholder'),
      allSelectedText: t('common.select.all_selected_text'),
      selectLabel: t('common.select.select_label'),
    }),
    [t],
  )

  return (
    <WppSelect
      ref={ref}
      {...rest}
      {...labelProps}
      getOptionLabel={getOptionLabel}
      withSearch={withSearch}
      withFolder={withFolder && !!options.length}
      className={clsx(
        styles.select,
        {
          [styles.withSearch]: withSearch,
          [styles.showJustClearAll]: infinite,
        },
        className,
      )}
      infinite={infinite}
      loading={loading}
      dropdownPosition={dropdownPosition}
      locales={locales ? { ...defaultLocales, ...locales } : defaultLocales}
      dropdownConfig={dropdownConfigInner}
      data-testid={dataTestId}
    >
      {customOption}
      {options.map(option => {
        const optionValue = getOptionValue(option)

        return (
          <WppListItem key={getOptionKey(option)} value={optionValue} disabled={isOptionDisabled(option)}>
            {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
          </WppListItem>
        )
      })}
      {!options.length && !loading && infinite && (
        <WppListItem disabled multiple={false}>
          <span slot="label">{t('common.select.empty_text')}</span>
        </WppListItem>
      )}
      {loading && (!!search || !options.length) && infinite && (
        <WppListItem disabled multiple={false} className={styles.loading}>
          <WppSpinner slot="label" />
        </WppListItem>
      )}
    </WppSelect>
  )
}) as <T extends Record<string, any>>(props: SelectProps<T>) => JSX.Element

import { WppLabel } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Hierarchy } from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs'
import { HierarchyTree } from 'components/hierarchyTree/HierarchyTree'
import { ExtendedEntityCommonFields } from 'types/masterData/utils'
import { mapEntityNames } from 'utils/entity'
import { routesManager } from 'utils/routesManager'

interface Props {
  entity: ExtendedEntityCommonFields
}

export const EntityDetailsHierarchy = ({ entity }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const modulePath = pathname.split('/')?.[1] as keyof typeof routesManager.masterData

  const hierarchy = useMemo<Hierarchy[]>(() => {
    const currentItem: Hierarchy = { id: entity.id, name: (entity.name || entity.displayName)! }
    return [currentItem, ...(entity.hierarchy || [])]
  }, [entity])

  if (!entity?.hierarchy?.length) return null

  return (
    <div>
      <WppLabel
        config={{
          text: t('info_cards.entity_structure', {
            entity: t(`common.master_data.entities.${mapEntityNames[modulePath]}`),
          }),
        }}
      />
      <HierarchyTree data={hierarchy} navigationTo={modulePath} />
    </div>
  )
}

export enum ApiQueryKeys {
  MASTER_DATA_AGENCIES = 'master-data-agencies',
  MASTER_DATA_AGENCIES_EXPORT = 'master-data-agencies-export',
  MASTER_DATA_AGENCY = 'master-data-agency',
  MASTER_DATA_AGENCY_INFINITE = 'master-data-agency-infinite',

  MASTER_DATA_BRANDS = 'master-data-brands',
  MASTER_DATA_BRANDS_EXPORT = 'master-data-brands-export',
  MASTER_DATA_BRAND = 'master-data-brand',
  MASTER_DATA_BRAND_INFINITE = 'master-data-brand-infinite',

  MASTER_DATA_CLIENTS = 'master-data-clients',
  MASTER_DATA_CLIENTS_EXPORT = 'master-data-clients-export',
  MASTER_DATA_CLIENT = 'master-data-client',
  MASTER_DATA_CLIENTS_INFINITE = 'master-data-clients-infinite',

  MASTER_DATA_INDUSTRIES = 'master-data-industries',
  MASTER_DATA_INDUSTRIES_EXPORT = 'master-data-industries-export',
  MASTER_DATA_INDUSTRY = 'master-data-industry',
  MASTER_DATA_INDUSTRIES_INFINITE = 'master-data-industries-infinite',

  MASTER_DATA_MARKETS = 'master-data-markets',
  MASTER_DATA_MARKETS_EXPORT = 'master-data-markets-export',
  MASTER_DATA_MARKET = 'master-data-market',
  MASTER_DATA_MARKETS_INFINITE = 'master-data-markets-infinite',

  MASTER_DATA_GEOGRAPHIES = 'master-data-geographies',
  MASTER_DATA_GEOGRAPHIES_EXPORT = 'master-data-geographies-export',
  MASTER_DATA_GEOGRAPHY = 'master-data-geography',
  MASTER_DATA_GEOGRAPHIES_INFINITE = 'master-data-geographies-infinite',

  MASTER_DATA_DOWNLOAD_URLS = 'master-data-download-urls',
  USER_DETAILS_DOWNLOAD_URLS = 'user-details-download-urls',
}

import { WppIconClock, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useDateFormat } from 'hooks/useDateFormat'
import styles from 'pages/components/entityDetailsCards/EntityDetailsCards.module.scss'
import { ExtendedEntityCommonFields } from 'types/masterData/utils'

interface Props {
  entity: ExtendedEntityCommonFields
}

export const EntityDetailsTimestamps = ({ entity }: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useDateFormat()
  return (
    <Flex gap={24} direction="column">
      {entity?.updatedAt && (
        <Flex direction="column">
          <WppLabel config={{ text: t('info_cards.last_updated') }} />
          <Flex gap={8}>
            <WppIconClock />
            <WppTypography type="s-body" className={styles.detail}>
              {t('info_cards.last_updated_at', {
                at: formatDate(entity.updatedAt),
              })}
            </WppTypography>
          </Flex>
        </Flex>
      )}
      <Flex direction="column">
        <WppLabel config={{ text: t('info_cards.created') }} />

        <WppTypography type="s-body" className={styles.detail}>
          {t('info_cards.created_at', {
            at: formatDate(entity?.createdAt!),
          })}
        </WppTypography>
      </Flex>
    </Flex>
  )
}

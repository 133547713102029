import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Geography, GeographyForm, GeographyFormDTO } from 'types/masterData/geographies'

export const defaultValues: GeographyForm = {
  name: '',
  aliases: [],
  logoThumbnail: null,
  logoOriginal: null,
}

export const convertToForm = (values: Geography): GeographyForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
  }

export const convertFormToFormDto = (values: GeographyForm): GeographyFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
})

export const nameFieldCharacterMaxCount = 256
export const aliasesFieldCharacterMinCount = 2
export const aliasesFieldCharacterMaxCount = 100

export const useGeographyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        name: zod
          .string()
          .trim()
          .min(
            2,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.region'),
              characterCount: 2,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.region'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(
          zod.object({
            id: zod
              .string()
              .min(aliasesFieldCharacterMinCount, {
                message: t('master_data.validation.aliases.min', {
                  characterCount: aliasesFieldCharacterMinCount,
                }),
              })
              .max(aliasesFieldCharacterMaxCount, {
                message: t('master_data.validation.aliases.max', {
                  characterCount: aliasesFieldCharacterMaxCount,
                }),
              }),
            label: zod.string(),
          }),
        ),
      }),
    [t],
  )
}

import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { EntityDetailsHierarchy } from 'pages/components/entityDetailsCards/components/EntityDetailsHierarchy'
import { EntityDetailsInputs } from 'pages/components/entityDetailsCards/components/EntityDetailsInputs'
import { EntityDetailsTimestamps } from 'pages/components/entityDetailsCards/components/EntityDetailsTimestamps'
import { ExtendedEntityCommonFields } from 'types/masterData/utils'

interface Props {
  entity: ExtendedEntityCommonFields
}

export const EntityDetailsCards = ({ entity }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <WppCard size="l">
        <WppTypography slot="header" type="xl-heading">
          {t('info_cards.change_history_title')}
        </WppTypography>
        <EntityDetailsTimestamps entity={entity} />
      </WppCard>

      <WppCard size="l">
        <WppTypography slot="header" type="xl-heading">
          {t('info_cards.info_title')}
        </WppTypography>

        <Flex direction="column" gap={24}>
          <EntityDetailsInputs entity={entity} />
          <EntityDetailsHierarchy entity={entity} />
        </Flex>
      </WppCard>
    </>
  )
}

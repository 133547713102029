import { WppAutocompleteCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { AutocompleteOptionList } from '@platform-ui-kit/components-library/dist/types/components/wpp-autocomplete/types'
import { ComponentProps, useCallback, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { useToast } from 'providers/toast/ToastProvider'

interface Props extends Omit<ComponentProps<typeof FormAutocomplete>, 'name' | 'options'> {
  name: string
}

export const FormInputAutocomplete = ({ name, ...rest }: Props) => {
  const { watch, setValue } = useFormContext()
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const values: AutocompleteOptionList = watch(name)
  const inputRef = useRef<HTMLWppAutocompleteElement>(null)

  const handleCreateNewItem = useCallback(
    ({ detail: itemText }: WppAutocompleteCustomEvent<string>) => {
      const newItem = itemText.trim()
      if (!newItem) return
      if (!values.some(item => item.id === newItem)) {
        const updatedValues: AutocompleteOptionList = [{ id: newItem, label: newItem }, ...values]
        setValue(name, updatedValues, { shouldDirty: true })
      } else {
        enqueueToast({
          message: t('master_data.validation.common.already_exists'),
          type: 'warning',
        })
      }
      // small hack, because `WppAutocomplete` does not allow to clean search or close dropdown programmatically
      ;(inputRef.current as any).searchValue = ''
    },
    [values, setValue, name, t, enqueueToast],
  )

  return (
    <FormAutocomplete
      ref={inputRef}
      simpleSearch
      name={name}
      onWppCreateNewOption={handleCreateNewItem}
      multiple
      options={values}
      showCreateNewElement
      displayBtnWhenListEmpty={false}
      {...rest}
    />
  )
}

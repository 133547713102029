import { useAsyncFn } from 'react-use'

import { useFetchGeographiesApi } from 'api/masterData/queries/useFetchGeographiesApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { queryClient } from 'providers/osQueryClient/utils'
import { Geography } from 'types/masterData/geographies'

interface Params {
  search?: string
}

export const handleReloadGeographiesListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_GEOGRAPHIES] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_GEOGRAPHY, { entryId }] }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_GEOGRAPHIES)
}

export const useGeographiesLoader = ({ search }: Params) => {
  const handleFetchGeographiesList = useFetchGeographiesApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const [{ loading: isLoading, value }, loader] = useAsyncFn<TableInfiniteLoader<Geography>>(
    async ({ startRow, endRow, sortModel }) => {
      const size = endRow - startRow
      const [{ colId, sort }] = sortModel

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchGeographiesList({
        itemsPerPage: size,
        sort: sort === 'asc' ? colId : `-${colId}`,
        search,
        page: endRow / size,
      })

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchGeographiesList],
  )

  return { loader, isLoading, hasResults: !!value?.totalRowsCount }
}

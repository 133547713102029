import { LabelConfig } from '@platform-ui-kit/components-library'
import { WppSegmentedControlCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { SegmentedControlChangeEventDetail } from '@platform-ui-kit/components-library/dist/types/components/wpp-segmented-control/types'
import { WppLabel, WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

export interface SegmentOption {
  value: string
  label: string
}

interface Props
  extends Omit<ComponentPropsWithoutRef<typeof WppSegmentedControl>, 'onChange' | 'onWppChange' | 'value'> {
  labelConfig?: LabelConfig
  name: string
  required?: boolean
  options: SegmentOption[]
  onControlItemClicked?: (value: string) => void
  handleChange?: (event: WppSegmentedControlCustomEvent<SegmentedControlChangeEventDetail>) => void
}

export const FormSegmentedControl = forwardRef<HTMLWppSegmentedControlElement, Props>(
  ({ name, options, labelConfig, onControlItemClicked, handleChange, required = false, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppSegmentedControlElement>(null)
    const { t } = useTranslation()
    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <Flex direction="column" gap={8}>
        {name && labelConfig && (
          <WppLabel
            config={{
              ...labelConfig,
              locales: {
                optional: t('common.label.optional'),
              },
            }}
            htmlFor={name}
            typography="s-strong"
            optional={!required}
          />
        )}
        <WppSegmentedControl
          {...rest}
          onWppBlur={onBlur}
          onWppChange={event => {
            onChange(event.detail.value)
            handleChange?.(event)
          }}
          ref={mergeRefs([innerRef, ref])}
          value={value}
        >
          {options.map(({ value, label }) => (
            <WppSegmentedControlItem
              onWppChangeSegmentedControlItem={() => onControlItemClicked?.(value)}
              value={value}
              key={value}
            >
              {label}
            </WppSegmentedControlItem>
          ))}
        </WppSegmentedControl>
      </Flex>
    )
  },
)

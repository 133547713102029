import { BreadcrumbItemEventDetails } from '@platform-ui-kit/components-library'
import { BreadcrumbItemState } from '@platform-ui-kit/components-library/dist/types/components/wpp-breadcrumb/types'
import { WppBreadcrumbCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppBreadcrumb } from '@platform-ui-kit/components-library-react'
import { ComponentProps, forwardRef, Ref, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { TableEmptyCell } from 'components/common/table'
import styles from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs.module.scss'
import { ExtendedEntityCommonFields } from 'types/masterData/utils'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof WppBreadcrumb> {
  hierarchy?: Hierarchy[]
  width?: number
  navigationTo: keyof typeof routesManager.masterData
  currentItem?: ExtendedEntityCommonFields
}

export interface Hierarchy {
  id: string
  name: string
  parentId?: string
}

const calcMaxLabelLength = (items: Hierarchy[], width: number) => {
  const coef = 500 / width
  const itemsLen = items.length
  switch (itemsLen) {
    case 1:
      return Number.MAX_SAFE_INTEGER
    case 2:
      return Math.floor(18 / coef)
    case 3:
      return Math.floor(12 / coef)
    case 4:
      return Math.floor(8 / coef)
    case 5:
      return Math.floor(6 / coef)
    default:
      return Math.floor(8 / coef)
  }
}

export const HierarchyBreadcrumbs = forwardRef(function HierarchyBreadcrumbs(
  { hierarchy = [], width, navigationTo, currentItem, ...rest }: Props,
  ref: Ref<HTMLWppBreadcrumbElement>,
) {
  const navigate = useNavigate()
  const mappedItems = useMemo<BreadcrumbItemState[]>(() => {
    const mapped = hierarchy.map(item => ({ label: item.name, path: item.id })).reverse()
    if (currentItem) {
      const appendItem: BreadcrumbItemState = {
        label: (currentItem.name || currentItem.displayName)!,
        path: currentItem.id,
      }
      return [...mapped, appendItem]
    }
    return mapped
  }, [hierarchy, currentItem])

  const handleClick = useCallback(
    (event: WppBreadcrumbCustomEvent<BreadcrumbItemEventDetails>) => {
      const entryId = event.detail.path
      navigate(routesManager.masterData[navigationTo].update.getURL({ entryId }))
    },
    [navigate, navigationTo],
  )

  if (!hierarchy?.length) return TableEmptyCell

  return (
    <div className={styles.body}>
      <WppBreadcrumb
        items={mappedItems}
        onWppChange={handleClick}
        maxLabelLength={calcMaxLabelLength(hierarchy!, width!)}
        middleTruncation
        ref={ref}
        {...rest}
      />
    </div>
  )
})

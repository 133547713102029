import { useEffect } from 'react'
import { RefCallBack } from 'react-hook-form'

import { useStableCallback } from 'hooks/useStableCallback'

export const useProvideFieldFocus = ({ fieldRef, setFocus }: { fieldRef: RefCallBack; setFocus: () => void }) => {
  const focus = useStableCallback(setFocus)

  useEffect(() => {
    fieldRef({ focus })

    return () => {
      fieldRef(null)
    }
  }, [fieldRef, focus])
}

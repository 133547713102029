import { WppInput, WppSkeleton, WppTooltip, WppIconError } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useCommonLabelProps } from 'components/common/utils'
import styles from 'components/form/formTextInput/FormTextInput.module.scss'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

export interface FormTextInputProps extends Omit<ComponentPropsWithoutRef<typeof WppInput>, 'value' | 'onChange'> {
  name: string
  withTooltipError?: boolean
}

export const FormTextInput = forwardRef<HTMLWppInputElement, FormTextInputProps>(
  (
    {
      name,
      message,
      messageType,
      onWppBlur,
      onWppChange,
      labelConfig,
      labelTooltipConfig,
      withTooltipError = false,
      className,
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLWppInputElement>(null)

    const {
      field: { ref: fieldRef, value, onChange, onBlur },
      fieldState: { isTouched, error },
    } = useField({ name })

    useProvideFieldFocus({
      fieldRef,
      setFocus: () => innerRef.current?.setFocus(),
    })

    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText
    const shouldShowErrorMessage = isTouched && !!errorText && !withTooltipError
    const shouldShowErrorTooltip = !!errorText && !!withTooltipError

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <>
        <WppInput
          {...rest}
          {...labelProps}
          ref={mergeRefs([innerRef, ref])}
          name={name}
          value={value}
          onWppChange={e => {
            onChange(e.detail.value)
            onWppChange?.(e)
          }}
          onWppBlur={e => {
            onBlur()
            onWppBlur?.(e)
          }}
          className={clsx(styles.input, className)}
          message={shouldShowErrorMessage ? errorText : message}
          messageType={shouldShowError ? 'error' : messageType}
        />
        {shouldShowErrorTooltip && (
          <WppTooltip className={styles.tooltipError} error text={errorText}>
            <WppIconError />
          </WppTooltip>
        )}
      </>
    )
  },
)

export const FormTextInputSkeleton = () => (
  <Flex gap={8} direction="column">
    <WppSkeleton width="30%" height={22} />
    <WppSkeleton height={40} />
  </Flex>
)

import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { is404Error } from 'api/utils'
import { LoadingPage } from 'components/surface/loadingPage/LoadingPage'
import { NotFound } from 'pages/404/NotFound'

interface Props {
  query: UseQueryReturnType
}

export const UpsertEntityHandleState = ({ query, children }: PropsWithChildren<Props>) => {
  const { state } = useLocation()
  const { isLoading, error } = query

  if (!state?.data && isLoading) {
    return <LoadingPage />
  }

  if (!isLoading && is404Error(error)) {
    return <NotFound />
  }

  return children
}

import { WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

import styles from 'components/breadcrumbs/Breadcrumbs.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { MarketTypeDefaultValue } from 'types/masterData/markets'
import { ExtendedEntityCommonFields, RouteModules } from 'types/masterData/utils'
import { mapEntityNames } from 'utils/entity'
import { routesManager } from 'utils/routesManager'

interface Props extends ComponentProps<typeof Flex> {
  entity?: ExtendedEntityCommonFields
}

export const Breadcrumbs = ({ entity, ...rest }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const moduleName = pathname?.split('/')?.[1] as RouteModules
  const isMarkets = moduleName === 'markets'
  const entityType = entity?.type || state?.type

  const searchParams = isMarkets
    ? createSearchParams({
        ...(entityType &&
          entityType !== MarketTypeDefaultValue && {
            type: entityType,
          }),
      }).toString()
    : undefined

  return (
    <Flex
      onClick={() =>
        navigate({
          pathname: routesManager.masterData[moduleName].root.getURL(),
          search: searchParams,
        })
      }
      className={styles.backButton}
      align="center"
      gap={4}
      data-testid="breadcrumbs-back-button"
      {...rest}
    >
      <WppIconChevron direction="left" />
      <WppTypography className={styles.buttonLabel} type="s-body">
        {t('navigation.back_to_entity', {
          entity: t(`common.master_data.lowercase_entities.${mapEntityNames[moduleName]}`, {
            count: 2,
          }),
        })}
      </WppTypography>
    </Flex>
  )
}

import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse, AttachmentMetadata } from '@wpp-open/core'

import { Hierarchy } from 'components/hierarchyBreadcrumbs/HierarchyBreadcrumbs'
import { EntityCommonFields } from 'types/masterData/utils'

export enum MarketType {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  CITY = 'CITY',
}
export const MarketTypeDefaultValue = MarketType.COUNTRY

interface Entity {
  id: string
  name: string
}

export interface MarketForm {
  name: string
  type: MarketType
  aliases: AutocompleteDefaultOption[]
  isoAlpha2: string
  isoAlpha3: string
  geographyIds: AutocompleteDefaultOption[]
  parentId: MayBeNull<Entity>
  stateOfCountry: MayBeNull<Entity>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  meta: MayBeNull<string>
}

export interface MarketFormDTO {
  name: string
  type: MarketType
  aliases: string[]
  isoAlpha2: string
  isoAlpha3: string
  geographyIds: string[]
  parentId: MayBeNull<string>
  logoThumbnail: MayBeNull<AttachmentMetadata>
  logoOriginal: MayBeNull<AttachmentMetadata>
}

export interface Market extends EntityCommonFields {
  name: string
  type: MarketType
  isoAlpha2: string
  isoAlpha3: string
  geographies: {
    id: string
    name: string
  }[]
  parent: MayBeNull<Pick<Market, 'id' | 'name'>>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  meta: MayBeNull<Object>
  hierarchy: Hierarchy[]
}

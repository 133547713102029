import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListGeographies } from 'pages/geographies/list/ListGeographies'
import { UpsertGeography } from 'pages/geographies/upsert/UpsertGeography'
import { routesManager } from 'utils/routesManager'

export const Geographies = () => (
  <Routes>
    <Route path={routesManager.masterData.geographies.root.relativePattern} index element={<ListGeographies />} />
    <Route path={routesManager.masterData.geographies.create.relativePattern} element={<UpsertGeography />} />
    <Route path={routesManager.masterData.geographies.update.relativePattern} element={<UpsertGeography />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

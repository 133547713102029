import { useTranslation } from 'react-i18next'

import { GeographyMarketsPills } from 'pages/geographies/upsert/components/GeographyMarketsPills'
import { useGeographyMarketsLoader } from 'pages/geographies/upsert/hooks/useGeographyMarketsLoader'
import { Geography } from 'types/masterData/geographies'
import { MarketType } from 'types/masterData/markets'

interface Props {
  geographyId: Geography['id']
}

export const GeographyMarketsByTypes = ({ geographyId }: Props) => {
  const { t } = useTranslation()
  const marketsCountries = useGeographyMarketsLoader({
    geographyId,
    filterType: MarketType.COUNTRY,
  })

  const marketsStates = useGeographyMarketsLoader({
    geographyId,
    filterType: MarketType.STATE,
  })

  const marketsCities = useGeographyMarketsLoader({
    geographyId,
    filterType: MarketType.CITY,
  })

  return (
    <>
      <GeographyMarketsPills
        marketsData={marketsCountries.marketsData}
        hasNextPage={marketsCountries.hasNextPage}
        fetchNextPage={marketsCountries.fetchNextPage}
        getAttachmentUrlByKey={marketsCountries.getAttachmentUrlByKey}
        isLoading={marketsCountries.isLoading}
        labelConfig={{ text: t('master_data.fields.countries.label') }}
      />

      <GeographyMarketsPills
        marketsData={marketsStates.marketsData}
        hasNextPage={marketsStates.hasNextPage}
        fetchNextPage={marketsStates.fetchNextPage}
        getAttachmentUrlByKey={marketsStates.getAttachmentUrlByKey}
        isLoading={marketsStates.isLoading}
        labelConfig={{ text: t('master_data.fields.states.label') }}
      />

      <GeographyMarketsPills
        marketsData={marketsCities.marketsData}
        hasNextPage={marketsCities.hasNextPage}
        fetchNextPage={marketsCities.fetchNextPage}
        getAttachmentUrlByKey={marketsCities.getAttachmentUrlByKey}
        isLoading={marketsCities.isLoading}
        labelConfig={{ text: t('master_data.fields.cities.label') }}
      />
    </>
  )
}

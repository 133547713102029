import {
  WppActionButton,
  WppButton,
  WppIconMore,
  WppIconPlus,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppMenuContext,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { ClientsListSortBy } from 'api/masterData/fetchers/fetchClientsList'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { ColDef, TableInfinite } from 'components/common/table'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDateFormat } from 'hooks/useDateFormat'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { showRemoveClientFromAgencyModal } from 'pages/agencies/upsert/components/RemoveClientFromAgencyModal'
import { showUpsertClientSideModal } from 'pages/agencies/upsert/upsertClientsSideModal/UpsertClientsSideModal'
import { useClientsLoader } from 'pages/clients/utils'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { AgencyForm } from 'types/masterData/agencies'
import { Client as ClientType } from 'types/masterData/clients'
import { CommonFilterState } from 'types/masterData/utils'

interface State extends CommonFilterState {
  agencyId: string[]
}

export const UpsertClientsTab = () => {
  const { t } = useTranslation()
  const { entryId } = useParams()
  const { formatDate } = useDateFormat()
  const form = useFormContext<AgencyForm>()

  const [{ search, agencyId, isEmptySearch }, setState] = useSetState<State>({
    isEmpty: false,
    isEmptySearch: false,
    search: '',
    agencyId: [entryId!],
  })

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const { loader } = useClientsLoader({
    search,
    agencyId,
  })

  const columnDefs = useMemo<ColDef<ClientType>[]>(
    () => [
      {
        flex: 1,
        colId: ClientsListSortBy.name,
        sortable: true,
        sort: 'asc',
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail } = data!
          return <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url!} userName={name} />
        },
        headerName: t('tables.clients.columns.name'),
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 1,
        colId: ClientsListSortBy.aliases,
        headerName: t('tables.clients.columns.aliases'),
        valueGetter: ({ data }) => data!.aliases.join(', ') ?? null,
        tooltipValueGetter: ({ data }) => data!.aliases.join(', ') ?? '-',
      },
      {
        flex: 0.5,
        colId: ClientsListSortBy.updatedAt,
        sortable: true,
        valueGetter: ({ data }) => formatDate(data?.updatedAt!) || formatDate(data?.createdAt!),
        headerName: t('tables.clients.columns.updated'),
      },
      {
        width: 60,
        colId: 'actions',
        cellRenderer: ({ data }) => {
          return (
            <WppMenuContext
              className={styles.moreMenu}
              dropdownConfig={{
                appendTo: () => document.body,
                placement: 'bottom-end',
              }}
            >
              <WppActionButton slot="trigger-element">
                <WppIconMore direction="horizontal" />
              </WppActionButton>

              <WppListItem
                onWppChangeListItem={() => showRemoveClientFromAgencyModal({ client: data!, form, agencyId: entryId! })}
              >
                <WppIconRemoveCircle slot="left" />
                <span slot="label">{t('common.remove')}</span>
              </WppListItem>
            </WppMenuContext>
          )
        },
      },
    ],
    [t, formatDate, form, entryId],
  )

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmptySearch, search })

  return (
    <>
      <div slot="header">
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          className={styles.searchInput}
          placeholder={t('common.search')}
        />
      </div>
      <div slot="actions">
        <WppButton
          size="s"
          variant="secondary"
          onClick={() =>
            showUpsertClientSideModal({
              agencyId: entryId!,
              form,
            })
          }
        >
          <WppIconPlus slot="icon-start" />
          {t('common.add')}
        </WppButton>
      </div>
      <TableInfinite
        tableKey={TableKey.MASTER_DATA_CLIENTS_AGENCY}
        className={clsx('clients-table', styles.table)}
        columnDefs={columnDefs}
        noRowsOverlayComponent={noRowsOverlayComponent}
        loader={loader}
        onLoadSuccess={({ isEmptySource }) => {
          setState({ isEmpty: isEmptySource, isEmptySearch: isEmptySource && !!search })
        }}
      />
    </>
  )
}

import { WppButton, WppInput, WppTypography } from '@platform-ui-kit/components-library-react'
import { RowSelectedEvent } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAsyncFn, useSetState } from 'react-use'

import { ClientsListSortBy } from 'api/masterData/fetchers/fetchClientsList'
import { useEditAgencyApi } from 'api/masterData/mutations/useEditAgencyApi'
import { AvatarWithNameCell } from 'components/cellRenderers/avatarWithName/AvatarWithNameCell'
import { AvatarWithInfoSkeleton } from 'components/cellRenderers/skeletons/avatarWithInfoSkeleton/AvatarWithInfoSkeleton'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, tableActions, TableInfinite } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { TableKey } from 'constants/table'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'pages/agencies/upsert/upsertClientsSideModal/UpsertClientsSideModal.module.scss'
import { convertFormToFormDto } from 'pages/agencies/upsert/utils'
import { useClientsLoader } from 'pages/clients/utils'
import { useTableNoRowsOverlay } from 'pages/hooks/useTableNoRowsOverlay'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Agency, AgencyForm, AgencyFormDTO } from 'types/masterData/agencies'
import { Client, Client as ClientType } from 'types/masterData/clients'
import { CommonFilterState } from 'types/masterData/utils'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  agencyId: Agency['id']
  form: UseFormReturn<AgencyForm>
}

interface State extends CommonFilterState {
  selectedRows: Client['id'][]
}

const UpsertClientsSideModal = ({ agencyId, form, isOpen, onCloseComplete, onClose, id }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { handleSubmit, getValues, setValue } = form

  const [{ search, isEmptySearch, selectedRows }, setState] = useSetState<State>({
    isEmpty: false,
    isEmptySearch: false,
    search: '',
    selectedRows: getValues('clientIds'),
  })

  const { mutateAsync: handleEdit } = useEditAgencyApi()

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const [{ loading: isSubmitting }, onSubmit] = useAsyncFn(
    async (values: AgencyForm) => {
      try {
        const agencyValues: AgencyFormDTO = {
          ...convertFormToFormDto(values),
          clientIds: selectedRows,
        }

        await handleEdit({
          id: agencyId,
          agency: agencyValues,
        })
        setValue('clientIds', selectedRows)

        await queryClient.invalidateQueries({
          queryKey: [ApiQueryKeys.MASTER_DATA_CLIENTS, { agencyId: [agencyId] }],
        })
        tableActions.reload(TableKey.MASTER_DATA_CLIENTS_AGENCY)
        onClose()

        enqueueToast({
          message: t('master_data.agency_clients.success_notification'),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('common.errors.general'),
          type: 'error',
        })
      }
    },
    [handleEdit, selectedRows, agencyId, onClose],
  )

  const onRowSelected = useStableCallback(({ data: changedItem, source }: RowSelectedEvent<Client>) => {
    if (source !== 'api') {
      setState(({ selectedRows }) => ({
        selectedRows: selectedRows.includes(changedItem!.id)
          ? selectedRows.filter(item => item !== changedItem!.id)
          : [...selectedRows, changedItem!.id],
      }))
    }
  })

  const { loader } = useClientsLoader({ search })

  const columnDefs = useMemo<ColDef<ClientType>[]>(
    () => [
      {
        width: 40,
        colId: 'select',
        checkboxSelection: true,
        onCellClicked: ({ node }) => {
          const isNodeSelected = node.isSelected()
          node.setSelected(!isNodeSelected)
        },
      },
      {
        flex: 1,
        colId: ClientsListSortBy.name,
        sortable: true,
        sort: 'asc',
        loadingCellRenderer: AvatarWithInfoSkeleton,
        cellRenderer: ({ data }) => {
          const { name, logoThumbnail } = data!
          return <AvatarWithNameCell avatarThumbnail={logoThumbnail?.url!} userName={name} />
        },
        headerName: t('tables.clients.columns.name'),
        tooltipValueGetter: ({ data }) => data!.name,
      },
      {
        flex: 1,
        colId: ClientsListSortBy.aliases,
        headerName: t('tables.clients.columns.aliases'),
        valueGetter: ({ data }) => data!.aliases.join(', ') ?? null,
        tooltipValueGetter: ({ data }) => data!.aliases.join(', ') ?? '-',
      },
    ],
    [t],
  )

  const noRowsOverlayComponent = useTableNoRowsOverlay({ isEmptySearch, search })

  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      disableOutsideClick
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="l"
    >
      <WppTypography slot="header" type="2xl-heading">
        {t('master_data.agency_clients.title')}
      </WppTypography>

      <Flex slot="body" direction="column" gap={12} className={styles.body}>
        <WppInput
          size="s"
          type="search"
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
          className={styles.searchInput}
          placeholder={t('common.search')}
        />

        <TableInfinite
          tableKey={TableKey.MASTER_DATA_CLIENTS_AGENCY_UPSERT}
          className={clsx('clients-table', styles.table)}
          columnDefs={columnDefs}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onRowSelected={onRowSelected}
          loader={loader}
          onLoadSuccess={({ isEmptySource }) => {
            setState({ isEmpty: isEmptySource, isEmptySearch: isEmptySource && !!search })
            tableActions.selectRows(TableKey.MASTER_DATA_CLIENTS_AGENCY_UPSERT, selectedRows)
          }}
        />
      </Flex>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" onClick={onClose} disabled={isSubmitting} data-testid="cancel">
          {t('common.cancel')}
        </WppButton>

        <WppButton
          variant="primary"
          onClick={() => handleSubmit(onSubmit)()}
          loading={isSubmitting}
          data-testid="apply"
        >
          {t('common.save')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showUpsertClientSideModal } = createNiceModal(UpsertClientsSideModal, 'upsert-clients-modal')

import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useInfiniteMarketsApi } from 'api/masterData/infiniteQueries/useInfiniteMarketsApi'
import { FormMarketsSelectWithSearch } from 'components/form/formSelectWithSearch/FormMarketsSelectWithSearch'
import { useStableCallback } from 'hooks/useStableCallback'
import { SchemeAdjustments } from 'pages/markets/upsert/UpsertMarket'
import { Market, MarketForm, MarketType } from 'types/masterData/markets'

interface Props {
  form: UseFormReturn<MarketForm>
  selectedCountryHasStates: boolean
  setSchemeAdjustments: ReturnType<typeof useSetState<SchemeAdjustments>>[1]
  market: Market
}

export const UpsertStateOrCountry = ({ form, selectedCountryHasStates, setSchemeAdjustments, market }: Props) => {
  const { t } = useTranslation()
  const { entryId } = useParams()
  const { watch, setValue, clearErrors, getFieldState } = form

  const [selectedState, selectedCountry] = watch(['stateOfCountry', 'parentId'])
  const { isDirty: isCountryDirty } = getFieldState('parentId')

  const handleCountryChanged = useStableCallback(() => {
    setValue('stateOfCountry', null)
    clearErrors('stateOfCountry')
  })

  const { data: statesByCountryId } = useInfiniteMarketsApi({
    enabled: !!selectedCountry?.id,
    params: {
      page: 1,
      itemsPerPage: 10,
      filterType: MarketType.STATE,
      parentId: [selectedCountry?.id as string],
    },
  })

  useEffect(() => {
    const hasStates = !statesByCountryId.every(item => item?.id === entryId)
    setSchemeAdjustments({
      selectedCountryHasStates: hasStates,
    })
  }, [statesByCountryId, setSchemeAdjustments, entryId])

  const hasStateAndCountry = market?.hierarchy?.length > 1
  const showStateInput = (hasStateAndCountry && !isCountryDirty) || selectedCountryHasStates

  return (
    <>
      <FormMarketsSelectWithSearch
        name="parentId"
        required
        filterType={MarketType.COUNTRY}
        onWppChange={handleCountryChanged}
        labelConfig={{
          text: t('master_data.fields.country.label'),
          description: t('master_data.fields.country.hint', {
            item: t(`common.${!!selectedState ? 'state_lowercase' : 'city_lowercase'}`),
          }),
        }}
      />

      {showStateInput && (
        <FormMarketsSelectWithSearch
          name="stateOfCountry"
          required
          filterType={MarketType.STATE}
          parentId={selectedCountry?.id as string}
          labelConfig={{
            text: t('master_data.fields.state.label'),
            description: t('master_data.fields.state.hint'),
          }}
        />
      )}
    </>
  )
}

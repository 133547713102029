import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchBrandApi } from 'api/masterData/queries/useFetchBrandApi'
import { Flex } from 'components/common/flex/Flex'
import { FormBrandsAutocomplete } from 'components/form/formAutocomplete/FormBrandsAutocomplete'
import { FormClientsAutocomplete } from 'components/form/formAutocomplete/FormClientsAutocomplete'
import { FormIndustriesAutocomplete } from 'components/form/formAutocomplete/FormIndustriesAutocomplete'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { useShowUnsavedConfirmationModal } from 'hooks/useShowUnsavedConfirmationModal'
import { ActionButtons } from 'pages/brands/upsert/components/ActionButtons'
import { convertToForm, defaultValues, useBrandValidationScheme } from 'pages/brands/upsert/utils'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { Brand } from 'types/masterData/brands'

export const UpsertBrand = () => {
  const { t } = useTranslation()
  const { entryId } = useParams()
  const { state } = useLocation()
  const brandState = state?.data as MayBeNull<Brand>

  const query = useFetchBrandApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const brand = query.data || brandState

  const form = useForm({
    defaultValues: brand ? convertToForm(brand) : defaultValues,
    validationSchema: useBrandValidationScheme(),
  })

  const {
    formState: { isDirty, isSubmitting },
  } = form

  useShowUnsavedConfirmationModal({ shouldBlock: isDirty && !isSubmitting })

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        query={query as UseQueryReturnType}
        title={t(`common.${brand ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.brand') })}
        actionButtons={<ActionButtons brand={brand!} form={form} />}
        rightCards={brand && <EntityDetailsCards entity={brand} />}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={brandState?.name || ''} />
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormInputAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormClientsAutocomplete
            multiple={false}
            required
            labelConfig={{
              text: t('master_data.fields.client.label'),
              description: t('master_data.fields.client.hint'),
            }}
            name="clientId"
          />
          <FormBrandsAutocomplete
            name="parentId"
            multiple={false}
            labelConfig={{
              text: t('master_data.fields.parent.label', { entity: t('common.master_data.lowercase_entities.brand') }),
              description: t('master_data.fields.parent.hint', {
                entity: t('common.master_data.lowercase_entities.brand'),
                entities: t('common.master_data.lowercase_entities.brand', { count: 2 }),
              }),
            }}
          />
          <FormReadonlyPills
            navigationTo="brands"
            labelConfig={{
              text: t('master_data.fields.subsidiaries.label'),
              description: t('master_data.fields.subsidiaries.hint', {
                entity: t('common.master_data.entities.brand'),
                entities: t('common.master_data.entities.brand', { count: 2 }),
              }),
            }}
            name="children"
            getItemLabel={item => item.name}
          />
          <FormIndustriesAutocomplete
            multiple
            labelConfig={{
              text: t('master_data.fields.industries.label'),
              description: t('master_data.fields.industries.hint'),
            }}
            name="industryIds"
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}

import { LoadMoreHandler, SelectTypes } from '@platform-ui-kit/components-library'
import { WppListItem } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useInfiniteMarketsApi } from 'api/masterData/infiniteQueries/useInfiniteMarketsApi'
import { SelectProps } from 'components/common/select/Select'
import { CustomOption } from 'components/form/formAutocomplete/CustomOption'
import { FormSelect } from 'components/form/formSelect//FormSelect'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { Market, MarketType } from 'types/masterData/markets'

export interface Props extends Omit<SelectProps<Market>, 'onChange' | 'value' | 'options'> {
  name: string
  type?: SelectTypes
  search?: string
  filterType?: MarketType
  parentId?: MayBeNull<string>
  'data-testid'?: string
}

export const FormMarketsSelectWithSearch = ({ type = 'single', filterType, parentId, name, ...rest }: Props) => {
  const [search, setSearch] = useState('')
  const { watch } = useFormContext()
  const currentValue = watch(name)

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteMarketsApi({
    params: {
      page: 1,
      itemsPerPage: AutocompleteInfinite.PageSize,
      search,
      filterType,
      ...(!!parentId && { parentId: [parentId] }),
    },
    staleTime: AutocompleteInfinite.StaleTime,
  })

  const setSearchDebounced = useDebounceFn((searchVal: string) => setSearch(searchVal.trim()), Delay.Search)

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(({ isFetched }) => {
          if (isFetched) {
            resolve()
          }
        })
      }),
  )

  const preselectedOption = () => {
    const hasInList = data.find(item => item.id === currentValue?.id)
    if (!hasInList && !!currentValue) {
      return (
        <WppListItem
          key={currentValue.id}
          value={{ name: currentValue?.name, id: currentValue?.id }}
          style={{ display: 'none' }}
        >
          <span slot="label">{currentValue.name}</span>
        </WppListItem>
      )
    }
  }

  return (
    <FormSelect
      {...rest}
      name={name}
      customOption={preselectedOption()}
      infinite
      infiniteLastPage={!hasNextPage}
      loadMore={handleLoadMore}
      options={data}
      type={type}
      withFolder
      withSearch
      search={search}
      onWppSearchValueChange={search => setSearchDebounced(search.detail)}
      getOptionLabel={option => option?.name}
      getOptionValue={({ id, name }) => ({ id, name })}
      getOptionId={option => option?.id}
      getOptionKey={({ id }) => id}
      renderOptionContent={option =>
        CustomOption({ id: option.id, label: option.name, avatarUrl: option.logoThumbnail?.url! })
      }
      loading={isLoading}
      showSelectAllText={false}
      withCustomValue
    />
  )
}

import { routesManager } from 'utils/routesManager'

export const mapEntityNames = {
  agencies: 'agency',
  brands: 'brand',
  clients: 'client',
  industries: 'industry',
  markets: 'market',
  geographies: 'geography',
} as const satisfies Record<keyof typeof routesManager.masterData, string>

import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchMarketApi } from 'api/masterData/queries/useFetchMarketApi'
import { Flex } from 'components/common/flex/Flex'
import { FormGeographiesAutocomplete } from 'components/form/formAutocomplete/FormGeographiesAutocomplete'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormSegmentedControl, SegmentOption } from 'components/form/formSegmentedControl/FormSegmentedControl'
import { FormMarketsSelectWithSearch } from 'components/form/formSelectWithSearch/FormMarketsSelectWithSearch'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { useShowUnsavedConfirmationModal } from 'hooks/useShowUnsavedConfirmationModal'
import { useStableCallback } from 'hooks/useStableCallback'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { ActionButtons } from 'pages/markets/upsert/components/ActionButtons'
import { UpsertStateOrCountry } from 'pages/markets/upsert/components/UpsertStateOrCountry'
import { MarketTypeLabel } from 'pages/markets/upsert/enums'
import { convertToForm, getDefaultValues, useMarketValidationScheme } from 'pages/markets/upsert/utils'
import { Market, MarketType, MarketTypeDefaultValue } from 'types/masterData/markets'

export interface SchemeAdjustments {
  selectedType: MarketType
  selectedCountryHasStates: boolean
}

export const UpsertMarket = () => {
  const { t } = useTranslation()

  const { entryId } = useParams()
  const { state } = useLocation()

  const marketState = state?.data as MayBeNull<Market>
  const marketType = state?.type as MayBeNull<MarketType>

  const query = useFetchMarketApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })

  const market = query.data || marketState

  const [schemeAdjustments, setSchemeAdjustments] = useSetState<SchemeAdjustments>({
    selectedType: market?.type! || marketType || MarketTypeDefaultValue,
    selectedCountryHasStates: false,
  })

  const form = useForm({
    defaultValues: market
      ? convertToForm(market)
      : getDefaultValues({
          ...(marketType && { type: marketType }),
        }),
    validationSchema: useMarketValidationScheme(schemeAdjustments),
  })

  const {
    watch,
    setValue,
    clearErrors,
    formState: { isDirty, isSubmitting },
  } = form

  const typeValue = watch('type')
  const isCountryType = typeValue === MarketType.COUNTRY
  const isStateType = typeValue === MarketType.STATE
  const isCityType = typeValue === MarketType.CITY

  useShowUnsavedConfirmationModal({ shouldBlock: isDirty && !isSubmitting })

  const marketTypeOptions = useMemo<SegmentOption[]>(
    () =>
      Object.values(MarketType).map(item => ({
        value: item,
        label: t(MarketTypeLabel[item]),
      })),
    [t],
  )

  const handleCountryChanged = useStableCallback(() => {
    setValue('stateOfCountry', null)
    clearErrors('stateOfCountry')
  })

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        query={query as UseQueryReturnType}
        title={t(`common.${market ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.market') })}
        actionButtons={<ActionButtons form={form} market={market!} />}
        rightCards={market && <EntityDetailsCards entity={market} />}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={marketState?.name || ''} />

          <FormSegmentedControl
            labelConfig={{
              text: t('master_data.fields.type.label'),
            }}
            required
            options={marketTypeOptions}
            name="type"
            handleChange={({ detail }) => {
              setSchemeAdjustments({ selectedType: detail.value as MarketType, selectedCountryHasStates: false })
            }}
          />

          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />

          {isStateType && (
            <FormMarketsSelectWithSearch
              name="parentId"
              required
              filterType={MarketType.COUNTRY}
              onWppChange={handleCountryChanged}
              labelConfig={{
                text: t('master_data.fields.country.label'),
                description: t('master_data.fields.country.hint', {
                  item: t('common.state_lowercase'),
                }),
              }}
            />
          )}

          {isCityType && (
            <UpsertStateOrCountry
              setSchemeAdjustments={setSchemeAdjustments}
              selectedCountryHasStates={schemeAdjustments.selectedCountryHasStates}
              form={form}
              market={market!}
            />
          )}

          <FormInputAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />

          {isCountryType && (
            <>
              <FormTextInput
                name="isoAlpha2"
                labelConfig={{
                  text: t('master_data.fields.iso_alpha_2.label'),
                  description: t('master_data.fields.iso_alpha_2.hint'),
                }}
                required
              />
              <FormTextInput
                name="isoAlpha3"
                labelConfig={{
                  text: t('master_data.fields.iso_alpha_3.label'),
                  description: t('master_data.fields.iso_alpha_3.hint'),
                }}
                required
              />
            </>
          )}

          <FormGeographiesAutocomplete
            multiple
            dropdownConfig={{
              placement: 'top',
            }}
            labelConfig={{
              text: t('master_data.fields.geography.label'),
              description: t('master_data.fields.geography.hint'),
            }}
            name="geographyIds"
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}

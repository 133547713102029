import {
  WppIconInfo,
  WppTextareaInput,
  WppInput,
  WppTooltip,
  WppLabel,
} from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/entityDetailsCards/EntityDetailsCards.module.scss'
import { ExtendedEntityCommonFields } from 'types/masterData/utils'

interface Props {
  entity: ExtendedEntityCommonFields
}

export const EntityDetailsInputs = ({ entity }: Props) => {
  const metadataString = useMemo(() => (entity?.meta ? JSON.stringify(entity.meta) : null), [entity])
  const { t } = useTranslation()

  return (
    <>
      <Flex direction="column">
        <WppLabel config={{ text: t('info_cards.master_data_id.label') }} />
        <WppInput className={styles.disabledField} disabled required value={entity.id} />
      </Flex>

      {metadataString && (
        <Flex direction="column">
          <Flex gap={8}>
            <WppLabel config={{ text: t('info_cards.technical_metadata.label') }} />
            <WppTooltip text={t('info_cards.technical_metadata.hint')}>
              <WppIconInfo />
            </WppTooltip>
          </Flex>
          <WppTextareaInput className={styles.disabledField} disabled required value={metadataString} />
        </Flex>
      )}
    </>
  )
}

import { LoadMoreHandler } from '@platform-ui-kit/components-library'
import { useMemo, useState } from 'react'

import { useInfiniteBrandsApi } from 'api/masterData/infiniteQueries/useInfiniteBrandsApi'
import { CustomOption } from 'components/form/formAutocomplete/CustomOption'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { AutocompleteExtendedOption, FormAutocompleteInfiniteProps } from 'components/form/formAutocomplete/utils'
import { AutocompleteInfinite } from 'constants/autocomplete'
import { Delay } from 'constants/delay'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useStableCallback } from 'hooks/useStableCallback'
import { AutocompleteOption } from 'types/common/utils'

type Props = Omit<FormAutocompleteInfiniteProps<AutocompleteOption>, 'getOptionLabel' | 'getOptionId'>

export const FormBrandsAutocomplete = (props: Props) => {
  const [search, setSearch] = useState('')

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteBrandsApi({
    enabled: !!search,
    params: {
      page: 1,
      itemsPerPage: AutocompleteInfinite.PageSize,
      search,
    },
    staleTime: AutocompleteInfinite.StaleTime,
  })

  const setSearchDebounced = useDebounceFn((search: string) => setSearch(search.trim()), Delay.Search)

  const handleLoadMore: LoadMoreHandler = useStableCallback(
    () =>
      new Promise(resolve => {
        fetchNextPage().then(({ isFetched }) => {
          if (isFetched) {
            resolve()
          }
        })
      }),
  )

  const options = useMemo<AutocompleteExtendedOption[]>(
    () =>
      data.map(item => ({
        id: item.id,
        label: item.name!,
        avatarUrl: item?.logoThumbnail?.url,
        caption: item.aliases.join(', '),
      })),
    [data],
  )

  return (
    <FormAutocomplete
      {...props}
      infinite
      infiniteLastPage={!hasNextPage}
      onWppSearchValueChange={({ detail }) => setSearchDebounced(detail)}
      loadMore={handleLoadMore}
      options={options}
      loading={isLoading}
      renderOptionContent={CustomOption}
    />
  )
}

import { useEffect, useRef } from 'react'
import { useBlocker } from 'react-router-dom'

import { showUnsavedConfirmationModal } from 'components/unsavedConfirmationModal/UnsavedConfirmationModal'

interface Props {
  shouldBlock: boolean
  callbackFn?: () => void
}

export const useShowUnsavedConfirmationModal = ({ shouldBlock, callbackFn }: Props) => {
  const proceedClicked = useRef(false) // fixes native browser navigation like "back button"
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (proceedClicked.current) return false
    const isNewPathname = currentLocation.pathname !== nextLocation.pathname
    const isNewSearch = currentLocation.search !== nextLocation.search
    return shouldBlock && (isNewPathname || isNewSearch)
  })
  const isBlocked = blocker.state === 'blocked'

  useEffect(() => {
    if (isBlocked) {
      showUnsavedConfirmationModal({
        onSubmit: () => {
          proceedClicked.current = true
          callbackFn?.()
          blocker.proceed()
        },
        onClose: () => blocker.reset(),
      })
    }
  }, [isBlocked, blocker, callbackFn])

  return isBlocked
}

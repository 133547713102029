import { LabelConfig } from '@platform-ui-kit/components-library'
import { DropdownConfigFloating } from '@platform-ui-kit/components-library/dist/types/types/common'
import { WppActionButton, WppLabel, WppPill, WppPillGroup } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useCommonLabelProps } from 'components/common/utils'
import { AvatarWithLabelPill } from 'components/form/formAutocomplete/AvatarWithLabelPill'
import { useGeographyMarketsLoader } from 'pages/geographies/upsert/hooks/useGeographyMarketsLoader'

interface Props extends ReturnType<typeof useGeographyMarketsLoader> {
  labelConfig?: LabelConfig
  labelTooltipConfig?: DropdownConfigFloating
  'data-testid'?: string
}

export const GeographyMarketsPills = ({
  marketsData,
  isLoading,
  fetchNextPage,
  getAttachmentUrlByKey,
  hasNextPage,
  labelConfig,
  labelTooltipConfig,
  'data-testid': dataTestId,
}: Props) => {
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const { t } = useTranslation()

  if (!marketsData.length) {
    return null
  }

  return (
    <Flex gap={8} direction="column" data-testid={dataTestId}>
      <WppLabel config={labelProps.labelConfig} tooltipConfig={labelProps.labelTooltipConfig} />
      <WppPillGroup type="display">
        <Flex wrap="wrap" gap={6}>
          {marketsData.map(item => (
            <WppPill removable={false} key={item.id}>
              <AvatarWithLabelPill label={item.name} avatarUrl={getAttachmentUrlByKey(item.logoThumbnail?.key)} />
            </WppPill>
          ))}
          {/* FIXME: using conditional rendering cases a bug in CL, that's why style used here */}
          <WppActionButton
            style={{ display: hasNextPage ? 'unset' : 'none' }}
            variant="primary"
            loading={isLoading}
            onClick={fetchNextPage}
          >
            {t('common.show_more')}
          </WppActionButton>
        </Flex>
      </WppPillGroup>
    </Flex>
  )
}

import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchAgencyApi } from 'api/masterData/queries/useFetchAgencyApi'
import { useForm } from 'hooks/form/useForm'
import { useShowUnsavedConfirmationModal } from 'hooks/useShowUnsavedConfirmationModal'
import { ActionButtons } from 'pages/agencies/upsert/components/ActionButtons'
import { UpsertClientsTab } from 'pages/agencies/upsert/components/UpsertClientsTab'
import { UpsertDetailsTab } from 'pages/agencies/upsert/components/UpsertDetailsTab'
import { Tabs, UpsertTabs } from 'pages/agencies/upsert/tabs/UpsertTabs'
import { defaultValues, convertToForm, useAgencyValidationScheme } from 'pages/agencies/upsert/utils'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { Agency } from 'types/masterData/agencies'

export const UpsertAgency = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isClientsTab = searchParams.get('type') === Tabs.clients

  const { entryId } = useParams()
  const { state } = useLocation()
  const agencyState = state?.data as MayBeNull<Agency>

  const query = useFetchAgencyApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })

  const agency = query.data || agencyState

  const form = useForm({
    defaultValues: agency ? convertToForm(agency) : defaultValues,
    validationSchema: useAgencyValidationScheme(),
  })

  const {
    formState: { isDirty, isSubmitting },
    watch,
    reset,
  } = form
  const title = watch('displayName')

  const isBlocked = useShowUnsavedConfirmationModal({
    shouldBlock: isDirty && !isSubmitting,
    callbackFn: () => reset(),
  })

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        title={t(`common.${agency ? 'update' : 'create'}_entity`, {
          entity: title,
        })}
        actionButtons={<ActionButtons agency={agency!} form={form} />}
        rightCards={!isClientsTab && agency && <EntityDetailsCards entity={agency} />}
        tabsSection={<UpsertTabs isBlocked={isBlocked} />}
        wrappedCardContent={false}
        query={query as UseQueryReturnType}
      >
        {isClientsTab ? <UpsertClientsTab /> : <UpsertDetailsTab agency={agency!} />}
      </UpsertEntityWrapper>
    </FormProvider>
  )
}

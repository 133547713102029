import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren, ReactNode } from 'react'

import { UseQueryReturnType } from 'api/common/types'
import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs'
import { Flex } from 'components/common/flex/Flex'
import { UpsertEntityContentBlock } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContentBlock'
import { UpsertEntityHandleState } from 'pages/components/upsertEntityWrapper/components/UpsertEntityHandleState'
import styles from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper.module.scss'

interface Props extends ComponentProps<typeof WppCard> {
  title: string
  actionButtons?: ReactNode
  rightCards?: ReactNode
  tabsSection?: ReactNode
  className?: string
  wrappedCardContent?: boolean
  query: UseQueryReturnType
}

export const UpsertEntityWrapper = ({
  actionButtons,
  tabsSection,
  rightCards,
  title,
  children,
  className,
  wrappedCardContent = true,
  query,
  ...rest
}: PropsWithChildren<Props>) => (
  <UpsertEntityHandleState query={query}>
    <Flex direction="column" gap={24} className={styles.container}>
      <Flex direction="column" gap={8}>
        <Breadcrumbs entity={query.data} />
        <Flex justify="between" gap={12} align="center">
          <WppTypography tag="h1" type="3xl-heading" className={styles.containerTitle}>
            {title}
          </WppTypography>
          {actionButtons && <Flex gap={12}>{actionButtons}</Flex>}
        </Flex>
      </Flex>

      {tabsSection}

      <Flex gap={24} className={styles.blockWrapper}>
        <WppCard
          size="xl"
          className={clsx(
            styles.cardWrapper,
            {
              [styles.cardWrapperWithCards]: rightCards,
            },
            className,
          )}
          {...rest}
        >
          <UpsertEntityContentBlock hasRightCards={!!rightCards} wrappedCardContent={wrappedCardContent}>
            {children}
          </UpsertEntityContentBlock>
        </WppCard>

        {rightCards && (
          <Flex direction="column" gap={24} className={styles.moreCardsBlock}>
            {rightCards}
          </Flex>
        )}
      </Flex>
    </Flex>
  </UpsertEntityHandleState>
)
